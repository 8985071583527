.App {
    width: 100%;
    height: 100%;
}

.bgImage {
    width: 100%;
    height: 500px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.MainLogo {
    width: 15%;
    margin: 0 auto;
    display: inherit;
    position: absolute;
    margin-top: -12%;
    left: 42%;
}

.GalleryImageDiv {
    height: 580px !important;
    position: relative;
}

.About {
    margin: 0 auto;
    width: 80%;
    margin-top: 70px;
}

.AppTopMenu {
    width: 100%;
    margin: 0 auto;
}
    
.TopPage {
    background: white;
    height: 50vh;
    width: 100%;
    color: white;
    font-size: 3vw;
    font-family: Verdana, Geneva, Tahoma, sans-serif, Courier, monospace;
    position: relative;
}

.TopPageLogo {
    width: 60vw;
    position: absolute;
    left: 50%;
    margin-left: -30vw;
    bottom: -15vw;
    z-index: 1;
}

.TopPageBackground {
    position: relative;
    width: 100%;
    height: 50vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.TopPageText {
    position: absolute;
    bottom: 5%;
    left: 5%;
}

.BottomPage {
    width: 100%;
    height: 30vh;
    background-color: #809a8e;
}

.BottomPageText {
    direction: rtl;
    font-weight: bold;
    font-size: 4vw;
    font-family: 'myriad-hebrew';
}

@media only screen and (min-width: 350px){
    .App {
        width: 100%;
        height: 100%;
    }
    
    .bgImage {
        width: 100%;
        height: 500px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }
    
    .AppTopMenu {
        width: 100%;
        margin: 0 auto;
    }
}

@media only screen and (min-width: 600px){
    .GalleryImageDiv {
        height: 860px !important;
    }

    .About {
        width: 80%;
        height: 50%;
    }
}

@media only screen and (min-width: 800px){
    .App {
        width: 100%;
        height: 1000px;
    }
    .bgImage {
        width: 100%;
        height: 900px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    .AppTopMenu {
        width: 100%;
        margin: 0 auto;
    }
    .TopPage {
        color: white;
        font-size: 3vw;
        font-family: Verdana, Geneva, Tahoma, sans-serif, Courier, monospace;
        position: relative;
    }

    .TopPageLogo {
        width: 30vw;
        margin-left: -15vw;
        bottom: -10vw;
    }

    .TopPageBackground {
        width: 100%;
        height: 100vh;
    }
    
    .TopPageText {
        position: absolute;
        bottom: 5%;
        left: 5%;
    }

    .BottomPage {
        height: 70%;
    }
}

@media only screen and (min-width: 1300px){
    .GalleryImageDiv {
        height: 1500px !important;
    }

    .About {
        width: 80%;
        height: 75%;
    }

    .TopPage {
        color: white;
        font-size: 3vw;
        font-family: Verdana, Geneva, Tahoma, sans-serif, Courier, monospace;
        position: relative;
    }
    
    .TopPageText {
        position: absolute;
        bottom: 5%;
        left: 5%;
    }

    .BottomPageText {
        direction: rtl;
        font-weight: bold;
        font-size: 2vw;
        font-family: 'myriad-hebrew';
    }
}