.AboutDiv {
    height: 300px;
    width: 100%;
    position: relative;
    background-color: #f3e29c;
}

.AboutDivText {
    border-radius: 2000px 0 0 0;
    background-color: #2d464e;
    height: 250px;
    width: 100%;
    position: absolute;
    bottom: 0;
}

.AboutText {
    position: relative;
    width: 55%;
    float: right;
    right: 4px;
    z-index: 2;
    color: black;
    direction: rtl;
    font-family: 'myriad-hebrew' !important;
}

.AboutTextHeadline {
    text-align: center;
    font-size: 20px !important;
    position: relative;
    top: 6vh;
}

.AboutTextBody {
    position: relative;
    right: 5px;
    top: 40px;
    font-size: 9.5px !important;
    /* margin-bottom: 10px !important; */
}

.Yoni {
    position: absolute;
    z-index: 1;
    bottom: -20px;
    height: 120%;
}

@media only screen and (min-width: 350px){

}

@media only screen and (min-width: 600px){
    .AboutTextBody {
        font-size: 12px !important;
        right: 50px;
    }
}

@media only screen and (min-width: 1000px){
    .AboutDiv {
        height: 600px;
    }

    .AboutDivText {
        height: 500px;
    }

    .AboutTextHeadline {
        font-size: 100px !important;
        top: 8vh;
    }

    .AboutTextBody {
        font-size: 22px !important;
    }

    .Yoni {
        bottom: -100px;
        height: 150%;
    }
}

@media only screen and (min-width: 1300px){
    .AboutDiv {
        height: 600px;
    }

    .AboutDivText {
        height: 800px;
    }

    .AboutTextHeadline {
        font-size: 75px !important;
    }

    .AboutTextBody {
        font-size: 27px !important;
    }

    .Yoni {
        bottom: -50px;
        height: 150%;
    }
}


























.AboutPaper {
    text-align: center;
    width: 100%;
    /* height: 300px; */
    /* max-height: 300px; */
    padding-top: 20px;
    padding-bottom: 20px;
    overflow: none;
}

.AboutBoxTextBold {
    font-weight: bold;
    font-size: 10px;
    font-family: "myriad-hebrew";
}

.AboutBoxTextRegular {
    font-weight: normal;
    font-size: 10px;
    font-family: "myriad-hebrew";
}

@media only screen and (min-width: 600px){

    .AboutBoxTextBold {
        font-weight: bold;
        font-size: 12px;
        margin-bottom: 5px;
    }
    
    .AboutBoxTextRegular {
        font-weight: normal;
        font-size: 12px;
        margin: 7px;
    }
}

@media only screen and (min-width: 1300px){
    .AboutPaper {
        text-align: center;
        width: 100%;
        /* height: 600px; */
        max-height: 600px;
        padding-top: 20px;
        padding-bottom: 20px;
        overflow: none;
    }

    .AboutBoxTextBold {
        font-weight: bold;
        font-size: 25px;
        margin: 5px;
        margin-bottom: 25px;
    }
    
    .AboutBoxTextRegular {
        font-weight: normal;
        font-size: 25px;
        margin: 10px;
        /* margin-bottom: 50px; */
    }
}