html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: 'myriad-hebrew';
  src: local('myriad-hebrew'), url(./res/Fonts/myriad-hebrew.woff2) format('woff2');
}

@font-face {
  font-family: 'Asimon AAA';
  src: local('Asimon AAA'), url(./res/Fonts/asimon-stencil-aaa.woff2) format('woff2');
}

