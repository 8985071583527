.TopMenu{
    height:100px;
}

.TopMenuButtonGrid {
    position: relative;
    top: -5px;
}

.TopMenuGrid {
    top: 10px;
    position: fixed;
    z-index: 5;
    width: 5vw !important;
}

.NavMenu {
    position: fixed;
    top: 0;
    right: 0;
    height: 7vh !important;
    background-color: white;
}

.navMenuButtonText {
    font-size: '5vw' !important;
}

.NavMenuButton {
    padding: 5px !important;
    min-height: 0 !important;
    min-width: 0 !important;
    margin: 5px !important;
}

.TopMenuButton {
    padding: 0 5px 0 5px !important;
    margin-right: 2px !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    background-color: #809a8e !important;
    color: white !important;
    top: 20px;
    height: 4vh;
    width: 5vw;
    border-radius: 0 20px 20px 0 !important;
    justify-content: right !important;
    min-width: 40px !important;
}

.titleBanBasarImage{
    display: none;
}

.titleBanBasarLogo{
    display: none;
}

.titleIcons {
    /* transform: scale(0.5); */
    padding: 0;
    color: black;
    font-size: 20px !important;
}

.titleIconButtons {
    padding: 0 !important;
    min-width: 0 !important;
    min-height: 0 !important;
}

.titleText {
    font-size: 5px !important;
    margin-right: 10px !important;
    color: white;
}

.titleIcon {
    margin-right: 10px !important;
    font-size: 18px !important;
}

@media only screen and (min-width: 350px){
    .TopMenu{
        height: 100px;
    }

    .titleBanBasarImage{
        display: none;
    }
    
    .titleBanBasarLogo{
        display: none;
    }

    .titleIcons {
        transform: scale(1);
    }

    .titleText {
        font-size: 11px !important;
    }

    .navMenu {
        height: 7vw !important;
    }
}

@media only screen and (min-width: 800px){
    .TopMenuButton {
        height: 8vh;
    }
}

@media only screen and (min-width: 1100px){
    .NavMenu {
        height: 5.5vw !important;
    }
}

@media only screen and (min-width: 1300px){
    .navMenuButtonText {
        font-size: 2vw !important;
    }

    .TopMenu{
        width: 10px;
        height: 100px;
    }

    .titleBanBasarImage{
        display: inline-block;
    }
    
    .titleBanBasarLogo{
        display: none;
    }

    .titleIcons {
        transform: scale(2);
    }

    .titleText {
        font-size: 20px !important;
    }

    .titleIcon {
        font-size: 30px !important;
    }

    .NavMenu {
        height: 4.5vw !important;
    }
}

@media only screen and (min-width: 1770px){
    .titleBanBasarLogo{
        display: inline-block;
    }
}