.CategoryTitle {
    font-weight: bold;
    text-align: center;
}

.MenuDiv {
    width: 100%;
    margin: 0 auto;
    top: 10vh;
    position: relative;
    margin-bottom: 10vh;
}

.PaperMenuItem {
    overflow: auto;
    position: relative;
    height: 100%;
}

.MenuItemImage {
    width: 100%;
    height: 100%;
    float: right;
    display: block;
    margin: 5px;
}

.veganImage {
    position: absolute;
    width: 10vw;
    height: 10vw;
    top: 10px;
    left: 5px;
}

.MenuItemText {
    direction: rtl;
    margin-left: 10%;
    text-align: center;
    width: 80%;
    font-family: 'myriad-hebrew';
}

.MenuItemName {
    position: relative;
    font-weight: bold;
    /* margin-bottom: 20px; */
    font-size: 15px;
    font-family: 'myriad-hebrew';
    top: 10px;
}

.MenuItemDescription {
    font-weight: bold;
    font-size: 13px;
    font-family: 'myriad-hebrew';
}

.MenuItemPrice {
    font-weight: bold;
    font-size: 20px;
    position: absolute;
    left: 5px;
    font-family: 'myriad-hebrew';
    bottom: 5px;
}

.MenuItemPriceWithImage {
    font-weight: bold;
    font-size: 20px;
    position: absolute;
    bottom: 10px;
    font-family: 'myriad-hebrew';
    right: 0;
    background-color: #231f20;
    color: white;
}

.MenuItemDrinkPrice {
    width: 90%;
    text-align: center;
}

.MenuTitle {
    color: #231f20;
    text-align: center;
    position: relative;
    font-family: 'myriad-hebrew' !important;
    font-size: 20px !important;
}

@media only screen and (min-width: 400px){
    .veganImage {
        width: 7vw;
        height: 7vw;
    }
}

@media only screen and (min-width: 700px){
    .veganImage {
        width: 5vw;
        height: 5vw;
    }
}

@media only screen and (min-width: 1000px){
    .MenuTitle {
        font-size: 100px !important;
    }

    .MenuItemDrinkPrice {
        text-align: start;
    }
}

@media only screen and (min-width: 1300px){
    .CategoryTitle {
        font-weight: bold;
        text-align: center;
    }

    .MenuDiv {
        width: 80%;
    }
    
    .PaperMenuItem {
        overflow: auto;
        position: relative;
    }
    
    .MenuItemImage {
        width: 100%;
        float: right;
        display: block;
        margin: 5px;
    }
    
    .MenuItemText {
        font-size: 35px;
        text-align: right;
    }
    
    .MenuItemName {
        font-weight: bold;
        margin-bottom: 30px;
        text-align: center;
    }
    
    .MenuItemDescription {
        font-weight: bold;
        font-size: 25px;
    }
    
    .MenuItemPrice {
        font-size: 25px;
    }

    .MenuItemPriceWithImage {
        font-size: 25px;
    }

    .MenuTitle {
        font-size: 75px !important;
    }
}