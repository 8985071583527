.GalleryImage {
    height: 10vw !important;
    top: 150px;
    width: 20vw;
    margin: 0 auto;
}

.NewGalleryImage {
    width: 19vw;
    height: 30vw;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 1px;
}

.NewGalleryImageLong {
    width: 48vw !important;
}

.GalleryDiv {
    width: 100%;
    height: 520px;
    background-color: #dfd4bd;
}

.GalleryTitle {
    color: #231f20;
    text-align: center;
    top: 50px;
    position: relative;
    font-size: 20px !important;
}

@media only screen and (min-width: 600px){
    .GalleryImage {
        height: 500px !important;
        top: 150px;
        margin: 0 auto;
    }

    .GalleryDiv {
        height: 800px;
    }
}

@media only screen and (min-width: 800px){
    .GalleryImage {
        height: 500px !important;
        top: 150px;
        margin: 0 auto;
    }

    .GalleryDiv {
        height: 800px;
    }
    .NewGalleryImage {
        width: 19.14vw;
        height: 20vw;
    }
}

@media only screen and (min-width: 1000px) {
    .GalleryTitle {
        font-size: 100px !important;
    }
}

@media only screen and (min-width: 1300px){
    .GalleryImage {
        height: 700px !important;
        top: 150px;
        margin: 0 auto;
    }

    .GalleryDiv {
        height: 1000px;
    }

    .GalleryTitle {
        font-size: 75px !important;
    }
}